import functions from '../functions';
import { list } from './list';

$(() => {
    listTravel.init();
});

const listTravel = {
    $form: null,
    form: null,
    $filter: null,
    filter: null,
    defaults: {
        _page: 1,
        perPage: 6,
        language: functions.getLang(),
        arrivalDateFrom: '',
        destination: '',
        countryId: '',
        objectGroupId: 6,
        guests: '',
        hasArrival: 2,
        hasDiscount: null,
        tagCategoryId: '',
        tagCategoryIds: '',
        objectTypeId: '',
        sortBy: '2:DESC'
    },
    params: {},
    isHome: false,
    $listItems: null,
    filterParams: {
        tagCategoryId: '',
        //sortBy: null, 
        objectName: null,
        hasDiscount: null,
        objectTypeId: '',
    },
    filterParamDefaults: {
        tagCategoryId: '',
        transportationType: '',
        objectName: null,
        hasDiscount: null,
        objectTypeId: '',
    },
    mainFields: ['arrivalDateFrom', 'destination', 'sortBy'],
    init() {
        this.$form = $('.search-form.search-travel');
        this.form = this.$form[0];

        if(! this.form || this.defaults.objectGroupId != this.$form.attr('data-objectgroupid')) {
            return;
        }

        this.$listItems = $('.list-items');
        this.isHome = this.$form.last().attr('data-ishome') || ! this.$listItems.length;

        this.listURL = this.$form.last().attr('data-listurl');
        this.defaults.objectGroupId = this.$form.last().attr('data-objectgroupid');
        this.defaults.transportationType = this.$form.attr('data-transportationtype');
        this.defaults.tagCategoryId = this.$form.attr('data-tagCategoryId') || '';


        // OLD
        // this.defaults.countryId = this.defaults.destination = this.$form.last().attr('data-countryid');

        this.defaults.destination = this.$form.attr('data-destination') ? this.$form.attr('data-destination') : this.defaults.countryId;

        this.$filter = $('.search-filter');

        this.filterParams.tagCategoryId = this.$form.last().attr('data-tagcategoryid');
        this.filterParams.tagCategoryIds = this.$form.last().attr('data-tagcategoryids');
        this.filterParams.hasDiscount = this.$form.attr('data-hasdiscount');

        this.$filter && (this.filterParams = functions.getFormData(this.$filter));
        
        this.$form.on('submit', e => {
            const $target = $(e.target);
            e.preventDefault();

            this.isHome = $target.attr('data-ishome') || this.isHome;

            if(this.isHome) {
                this.params.objectGroupId = $target.attr('data-objectgroupid');
                this.params.countryId = this.params.destination = $target.attr('data-countryid');
                this.params.perPage = $target.attr('data-perpage') || this.defaults.perPage;
            }

            this.params = $.extend({}, this.defaults, functions.getFormData($target), this.$filter && functions.getFormData(this.$filter));
            this.params._page = this.defaults._page;

            if(this.isHome) {
                this.params.objectGroupId = $target.attr('data-objectgroupid');
                this.params.tagCategoryId = $target.attr('data-tagcategoryid');
                this.params.tagCategoryIds = $target.attr('data-tagcategoryids');
                this.params.hasDiscount = $target.attr('data-hasdiscount');
                
                location = $target.attr('data-listurl') + '#' + $.param(this.params);
            } else {
                functions.setUrlData(this.params, true, true);
            }

        });

        if(! this.isHome) {

            this.getParams();           
            functions.setFormData(this.form, this.params);
            this.params.guests = $('[name=guests]').val();
            // this.params._page = 1;
            this.find(this.params);
        } else {
            functions.setFormData(this.form, this.defaults);
        }

        if ( this.isHome ) {
            return;
        }

        
        this.$filter && functions.setFormData(this.$filter[0], this.params);
        this.$filter.on('change', e => {

            const $currentTarget = $(e.currentTarget);
            this.filterParams = $.extend({}, this.filterParamDefaults, functions.getFormData(this.$filter));
            this.params = functions.getFormData(this.$form);
            const target = $(e.target)[0];
            this.params._page = this.defaults._page;

            // if(target && target.name == 'transportationType') {

                if (target && target.name && ['objectTypeId', 'transportationType'].includes(target.name))
                {
                    $('[name="' + target.name + '"]').each((index, item) => {
                        if(target.id != item.id) {
                            $(item).prop('checked', false);
                        }
                    });
    
                    this.filterParams[target.name] = target.checked ? target.value : this.defaults[target.name];
                }
            // }

            this.getParams();
            this.params = $.extend({}, this.defaults, this.params, this.filterParams);
            this.params._page = this.defaults._page;
            
            functions.setUrlData(this.params, true, true);
        });

        $('.ms-clear').on('click', e => {
            e.preventDefault();
            e.stopPropagation();
            this.params.destination = '';
            $(this.form.destination).val('').trigger('change');
        });

        $('[name="tagCategoryId"]').on('change', e => {
            this.params._page = this.defaults._page;
            // this.getParams();
            this.setTagCategoryId();
            // this.find(this.params);

            // functions.setUrlData(this.params, true, true);
        });
        $('[name="hasDiscount"]').on('change', e => {
            this.params._page = this.defaults._page;
            this.getParams();
            this.params.hasDiscount = e.currentTarget.checked ? $(e.currentTarget).val() : null;
            functions.setUrlData(this.params, true, true);
        });

        $(window).on('hashchange', e => {
            this.getParams();
            this.setTagCategoryId();
            this.stopChangeEvents = true;

            // functions.scrollToElement(this.$listItems, 100);
            this.find(this.params);
            this.stopChangeEvents = false;
        });
    },
    getParams() {      
        this.params = list.getParams(this.params, this.defaults, this.filterParams);

        if(this.params.arrivalDateFrom) {
            
            let dateFrom = new Date(this.params.arrivalDateFrom);
            let month = dateFrom.getMonth() + 1;
            
            let lastDateOfMonth = new Date(dateFrom.getFullYear(), month, 0);
            this.params.arrivalDateTo = lastDateOfMonth.getFullYear() + '-' + (lastDateOfMonth.getMonth() + 1) + '-' + lastDateOfMonth.getDate();
        }
    },
    setTagCategoryId() {
        let tagCategoryIds = [];
        $('[name="tagCategoryId"]').each((index, target) => {
            const $target = $(target);
            if($target[0].checked) {
                tagCategoryIds.push($target.val());
            }
        });
        this.params.tagCategoryId = tagCategoryIds.join(",");
    },
    find(params) {
        list.find(params).then((response) => {
            list.findDone(response);
            // LOZAD
            window.observer.observe();
        });
    },

};